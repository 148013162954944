






import { defineComponent, computed } from 'nuxt-composition-api'

export default defineComponent({
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true as true
    }
  },
  setup(props) {
    const { name } = props
    const iconPath = computed(() => {
      return `/${name}.svg#root`
    })

    return {
      iconPath
    }
  }
})
