


































import { defineComponent, ref, useContext } from 'nuxt-composition-api'
import Icon from '@/components/Base/Icon'

export default defineComponent({
  name: 'Header',
  components: {
    Icon
  },
  props: {
    shrink: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const query = ref('')
    const inputRef = ref<HTMLInputElement>(null)

    const {
      app: { router }
    } = useContext()

    const searchPosts = (e: Event) => {
      // e.preventDefault()
      if (!query.value || !inputRef.value || !router) return
      inputRef.value.blur()
      router.push({ path: `/search?query=${query.value}` })
      query.value = ''
    }

    return {
      query,
      inputRef,
      searchPosts
    }
  }
})
